import React, { useMemo, useEffect } from 'react'
import { Link, graphql, navigate } from 'gatsby'
import Wrapper from '../components/wrapper'
import cookies from 'react-cookies'
import { useSelector } from 'react-redux'
import { permissionsHandler } from '../functions'

const PageDetail = ({ data, location }) => {
  let user = cookies.load('user')
  const storeProducts = useSelector(state => state.user).products
  let products = permissionsHandler(storeProducts, user?.role?.id)

  const infogramData = useMemo(() => {
    const infogramSection = (data?.strapiPage.content || []).find(item => {
      if (user && products?.subscriptionPurchased) {
        return (
          item.strapi_component === 'sections.infogram' &&
          item?.gate?.access === 'Subscriber'
        )
      }

      if (user) {
        return (
          item.strapi_component === 'sections.infogram' &&
          item?.gate?.access === 'Authenticated'
        )
      }

      return (
        item.strapi_component === 'sections.infogram' &&
        item?.gate?.access === 'Public'
      )
    })
    return infogramSection
  }, [data?.strapiPage, user, products])

  const handleRender = () => {
    const scriptId = 'infogram-async'

    if (!document.getElementById(scriptId)) {
      const script = document.createElement('script')
      script.async = true
      script.id = scriptId
      script.src = 'https://e.infogram.com/js/dist/embed-loader-min.js'

      const firstScript = document.getElementsByTagName('script')[0]
      firstScript.parentNode.insertBefore(script, firstScript)
    }

    setTimeout(() => {
      window['InfogramEmbeds']?.process()
    }, [100])

    return () => {
      const scriptElement = document.getElementById(scriptId)
      if (scriptElement) {
        scriptElement.parentNode.removeChild(scriptElement)
      }
    }
  }

  useEffect(() => {
    if (infogramData) {
      return handleRender()
    }
  }, [infogramData])

  return (
    <Wrapper
      location={location}
      title={`${data?.strapiPage?.title} | MedReps.com`}
    >
      <>
        {infogramData && (
          <div className="flex justify-center">
            <div
              style={{ maxWidth: `${infogramData.width}px` }}
              class="infogram-embed w-full"
              data-id={infogramData.source}
              data-type="interactive"
              data-title={data?.strapiPage?.title}
            ></div>
          </div>
        )}
        {!infogramData && (
          <div className="text-center w-full min-h-[400px] flex justify-center items-center">
            Page not found
          </div>
        )}
      </>
    </Wrapper>
  )
}

export default PageDetail
export const pageQuery = graphql`
  query ($slug: String!) {
    strapiPage(slug: { eq: $slug }) {
      id
      title
      slug
      content
    }
  }
`
